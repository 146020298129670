import React from "react"
import {Animated} from "react-animated-css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import withStyles from "@material-ui/styles/withStyles"

import {isMobile} from "react-device-detect"
import colors from "../colors"
import Layout from "../components/layout"

import sylvain from "../images/team/sylvain.jpeg"
import mathieu from "../images/team/mathieu.jpg"

const avatars = [
    {
        name: "Sylvain Chauffaille",
        title: "Président & CTO",
        src: sylvain,
        desc:
            "Fort de plusieurs années d'expérience au sein d'un éditeur de logiciel, j'ai affiné mes méthodologies en architecture logiciel ainsi qu'en conception d'infrastructre web. Avide de nouvelles techniques et langages, j'ai cultivé le renouvellement perpetuel des technologies pour toujours tendre vers la modernité et l'efficacité. Je mets aujourd'hui mes compétences à disposition afin de déceler et mettre en place les technologies avant-gardiste, tout en déjouant les éventuels risques.",
        skills: [
            "React & React Native",
            "Expert MeteorJs & PHP",
            "Next.JS",
            "Administration SI",
        ],
    },
    {
        name: "Mathieu Mayjonade",
        title: "Project Manager & Lead Tech",
        src: mathieu,
        desc:
            "Avec plus de 15 ans d’expérience dans la création d'applications, j’ai acquis les compétences nécessaires pour l’élaboration de projets du cahier des charges jusqu'à la mise en production en passant par la conception et l’infrastructure. Je suis passionné de développement et je recherche en permanence de nouveaux défis, tout en restant dans une relation de confiance avec le client.",
        skills: ["Gestion de projet", "Administration SI", "React & React Native", "Next.JS", "PhP"],
    },
]

const styles = {
    wrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        maxWidth: "100%",

        "& h2": {
            margin: "1rem",
            fontSize: "2rem",
            marginLeft: "15%",
        },
    },

    half: {
        display: "flex",
        justifyContent: "center",
        margin: "1rem 0",
        overflowX: "auto",
        overflowY: "hidden",
        maxWidth: "100%",
    },

    divider: {
        border: "1px solid lightgrey",
        width: "100%",
        marginTop: "1px",
    },

    avatarsWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-around",

        "@media (max-width:600px)": {
            maxHeight: "200px",
            overflow: "hidden",
        },
    },

    avatarContainer: {
        width: `calc(100% / ${avatars.length + 2})`,
        minWidth: "186px",
        maxWidth: "215px",
        position: "relative",
        backgroundColor: "rgba(110, 110, 110, 0.2)",

        "@media (max-width:600px)": {
            width: "100%",
        },
    },

    avatarLegend: {
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold",
        margin: "2px",
        "@media (max-width:600px)": {
            display: "none",
        },
    },

    avatarName: {
        color: "white",
        fontSize: "1.2rem",
    },

    avatarTitle: {
        color: colors.bleu,
        whiteSpace: "nowrap",
    },

    avatar: {
        width: "100%",
        transition: "all .2s ease",

        "&>img": {
            width: "100%",
        },
    },

    heightProfile: {
        textAlign: "justify",
        height: "calc(55% - 1rem)",
        alignItems: "center",
        position: "relative",

        "@media (max-width:600px)": {
            height: "60%",
        },
    },

    profile: {
        width: "70%",

        borderRadius: 16,
        height: "90%",
        color: "white",
        padding: 16,
        fontSize: "1.2rem",
        overflow: "hidden",

        "@media (max-width:600px)": {
            fontSize: "0.9rem",
            width: "100%",
            minWidth: "100%",
            paddingLeft: "1rem",
        },

        "&>div": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
        },

        "& h3": {
            margin: "0px",
            fontSize: "2rem",

            "@media (max-width:600px)": {
                fontSize: "1.1rem",
            },

            "&>span": {
                fontWeight: "normal",
            },
        },

        "& .skills": {
            display: "flex",
            flexWrap: "wrap",
            margin: "0 -0.5rem",

            "& span": {
                padding: ".25rem 0.5rem",
            },
        },
    },

    arrow: {
        color: "white",
        border: "1px solid white",
        borderRadius: "50%",
        minWidth: "48px",
        minHeight: "48px",
        maxWidth: "48px",
        maxHeight: "48px",
        margin: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top: "75px",
    },
}

class Avatar extends React.Component {
    render() {
        const {
            handleMouseLeave,
            handleMouseEnter,
            handleClick,
            classes,
            name,
            title,
            src,
            idx,
            visible,
            currentUser,
        } = this.props

        const scaleTo =
            isMobile || currentUser !== avatars[idx] ? 1 : visible ? 1.28 : 1

        return (
            <div className={classes.avatarContainer}>
                <div
                    style={{borderTopRightRadius: "50%"}}
                    className={`${classes.avatarName} ${classes.avatarLegend}`}
                >
                    {name}
                </div>
                <img
                    alt={title}
                    onClick={handleClick(idx)}
                    onMouseEnter={handleMouseEnter(idx)}
                    onMouseLeave={handleMouseLeave}
                    src={src}
                    className={`${classes.avatar} paperize`}
                    style={{
                        transform: `scale(${scaleTo})`,
                    }}
                />
                <div className={`${classes.avatarTitle} ${classes.avatarLegend}`}>
                    {title}
                </div>
            </div>
        )
    }
}

class AboutUs extends React.Component {
    timeoutClean = null

    timeoutShow = null

    animationTime = 800

    state = {
        currentUser: isMobile ? avatars[0] : null,
        clicked: false,
        visible: isMobile,
    }

    handleClick = () => () => this.setState({clicked: true})

    handleMouseEnter = idx => () => this.showDesc(idx, false)

    showDesc = (idx, clicked) => {
        const {visible} = this.state

        clearTimeout(this.timeoutClean)

        this.setState({visible: false, clicked}, () => {
            this.timeoutShow = setTimeout(
                () => this.setState({currentUser: avatars[idx], visible: true}),
                visible ? this.animationTime : 0
            )
        })
    }

    handleMouseLeave = () => {
        clearTimeout(this.timeoutShow)
        !this.state.clicked &&
        this.setState({visible: false}, () => {
            this.timeoutClean = setTimeout(
                () => this.setState({currentUser: null}),
                this.animationTime
            )
        })
    }

    swipe = (toTheRight = true) => {
        const indexUser = this.getIndexCurrentUser()
        const nextIndex = toTheRight ? indexUser + 1 : indexUser - 1

        if (indexUser > -1 && indexUser <= avatars.length)
            this.showDesc(nextIndex, true)
    }

    getIndexCurrentUser = () => {
        const {currentUser} = this.state

        return !currentUser
            ? -1
            : avatars.findIndex(user => user.name === currentUser.name)
    }

    swipingRight = () => this.swipe(true)

    swipingLeft = () => this.swipe(false)

    render() {
        const {classes, isCurrentScreen} = this.props
        const {currentUser, visible} = this.state

        const indexUser = this.getIndexCurrentUser()
        const canSwipeLeft = indexUser > 0 && isMobile
        const canSwipeRight = indexUser < avatars.length - 1 && isMobile

        console.log("avatars.length:", avatars.length)
        console.log("canSwipeLeft:", canSwipeLeft)
        console.log("canSwipeRight:", canSwipeRight)

        return (
            <Layout page="aboutUs">
                <div className={classes.wrapper}>
                    <div className="basic-screen-padding">
                        {isMobile && (
                            <Animated
                                animationIn="rotateInUpLeft"
                                animationOut="rotateOutUpLeft"
                                isVisible={isMobile ? visible : true}
                                style={{animationDuration: `${this.animationTime / 1000}s`}}
                            >
                                <div
                                    className={classes.arrow}
                                    style={{
                                        float: "left",
                                        visibility: canSwipeLeft ? "visible" : "hidden",
                                    }}
                                    onClick={() => {
                                        if (canSwipeLeft) this.swipingLeft()
                                    }}
                                >
                                    <FontAwesomeIcon icon="chevron-left"/>
                                </div>
                            </Animated>
                        )}
                        {isMobile && (
                            <Animated
                                animationIn="rotateInUpRight"
                                animationOut="rotateOutUpRight"
                                isVisible={isMobile ? visible : true}
                                style={{animationDuration: `${this.animationTime / 1000}s`}}
                            >
                                <div
                                    className={classes.arrow}
                                    style={{
                                        float: "right",
                                        visibility: canSwipeRight ? "visible" : "hidden",
                                    }}
                                    onClick={() => {
                                        if (canSwipeRight) this.swipingRight()
                                    }}
                                >
                                    <FontAwesomeIcon icon="chevron-right"/>
                                </div>
                            </Animated>
                        )}
                        <div
                            className={classes.half}
                            style={{
                                height: "calc(45% - 4rem)",
                                flexDirection: "column",
                            }}
                        >
                            <Animated
                                animationIn="zoomInDown"
                                animationOut="zoomOutUp"
                                isVisible={isMobile ? visible : isCurrentScreen}
                                style={{animationDuration: `${this.animationTime / 1000}s`}}
                            >
                                <div className={classes.avatarsWrapper}>
                                    {avatars
                                        .filter(
                                            avatar =>
                                                !isMobile ||
                                                (isMobile && avatar.name === currentUser.name)
                                        )
                                        .map((avatar, idx) => (
                                            <Avatar
                                                key={idx}
                                                idx={idx}
                                                classes={classes}
                                                handleMouseEnter={this.handleMouseEnter}
                                                handleMouseLeave={this.handleMouseLeave}
                                                handleClick={this.handleClick}
                                                currentUser={currentUser}
                                                isCurrentScreen={isCurrentScreen}
                                                visible={visible}
                                                {...avatar}
                                            />
                                        ))}
                                </div>
                            </Animated>
                        </div>
                        <div className={`${classes.half} ${classes.heightProfile}`}>
                            <div
                                className={classes.profile}
                                style={{
                                    background: currentUser ? "rgba(0,0,0,0.5)" : undefined,
                                }}
                            >
                                {currentUser && (
                                    <Animated
                                        animationIn="fadeInUp"
                                        animationOut="fadeOutDown"
                                        isVisible={visible}
                                        style={{
                                            animationDuration: `${this.animationTime / 1000}s`,
                                        }}
                                    >
                                        <h3>
                                            {currentUser.name}
                                            <br/>
                                            <span style={{color: colors.bleu}}>
                        {currentUser.title}
                      </span>
                                        </h3>
                                        <div className="desc">{currentUser.desc}</div>
                                        <div>
                                            <div
                                                style={{
                                                    color: colors.gris,
                                                    marginBottom: ".5rem",
                                                }}
                                            >
                                                Compétences
                                            </div>
                                            <div className="skills">
                                                <span>{currentUser.skills.join(", ")}</span>
                                            </div>
                                        </div>
                                    </Animated>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default withStyles(styles)(AboutUs)
